import React from 'react';
import ReCAPTCHA from "react-google-recaptcha"
import CreatableSelect from 'react-select/creatable'
import InputMask from 'react-input-mask'
import { PLACES, MIPT, checkPhone, getCookie, CAPTCHA_SITE_KEY } from '../utils/Consts'
import { register, logIn, recover, bindCard, deleteCard, getUserInfo, changePass, addAddressToUser } from '../utils/Api';

function LkPopup(props) {

    const place_id = 1;
    const [loading, setLoading] = React.useState('');
	const [need_check_errors, setErrorCheck] = React.useState(false);
	const [message, setMessage] = React.useState("");
    const [success, setSuccess] = React.useState("");
	const recaptcha_ref1 = React.createRef();
    const recaptcha_ref2 = React.createRef();
    const [isRecoveryVisible, setIsRecoveryVisible] = React.useState(false);

    const [activeTab, setActiveTab] = React.useState(1);
    const cards = props.user?.cards;
    const [selectedCard, setSelectedCard] = React.useState(0);

    const [newPass, setNewPass] = React.useState('');
	const [newPass2, setNewPass2] = React.useState('');

    const addresses = props.user?.addresses.filter((a)=>{return a.place_id === 1});
	const [editAdress, setEditAddress] = React.useState({
		'id': 0,
		'place_id': 0,
		'street': '',
		'house': '',
		'room': '',
		'title': '',
		'new_name': '',
	});
	const [addAddress, setAddAddress] = React.useState({
		'title': '',
		'place_id': 0,
		'street': '',
		'house': '',
		'room': '',
	});

    const [registerValues, setRegisterValues] = React.useState(
		JSON.parse(localStorage.getItem('registerValues')) ?? 
		{
			phone: '',
			name: '',
			place_title: PLACES.find(p => p.id === place_id)?.title, // Храним именно название города, а не id (так проще по коду)
			street: '',
			house: '',
			room: '',
			offer_checked: false,
		}
	);

    const [loginValues, setLoginValues] = React.useState(JSON.parse(localStorage.getItem('loginValues')) ??
	{
		phone: '',
		pass: '',	
	});



	const handleChangeRegister = name => (event, action) => {
		switch (name) {
			case 'street':
				if (action?.action === 'clear')
                    setRegisterValues({...registerValues, [name]: ''});
                else
                    setRegisterValues({...registerValues, [name]: event.value});
                break;
        	default:
				setRegisterValues({ ...registerValues, [name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value});
			break;
		}
    };

	const handleRegister = (e) => {
        e.preventDefault();
        setLoading("reg");
		setErrorCheck("reg");
		if (!checkPhone(registerValues.phone)
			|| !registerValues?.name
			|| !(registerValues?.place_title === "МФТИ" || registerValues?.street)
			|| !registerValues?.house
			|| !registerValues?.room) {
				setLoading('');
				setMessage('Заполните, пожалуйста, все поля');
				return;
			}
		if (!registerValues?.offer_checked) {
			setLoading('');
			setMessage('Необходимо принять условия оферты');
			return;
		}
		const captcha = recaptcha_ref2.current;
		if (!captcha?.getValue()) {
			setLoading('');
			setMessage('Пройдите, пожалуйста, капчу');
            captcha.reset();
			return;
		}

		// Всё хорошо, регистрируем
		register({...registerValues, grecaptcha: captcha.getValue()})
        .then(data=>{
		    setSuccess('Пароль отправлен вам по СМС');
            console.log(1);
        })
		.catch (async (e) => {
            const response = await e.res.json();
			setMessage(response.error_description.slice(7, ));
			return;
		})
        .finally(()=>{
            setLoading('');
            captcha.reset();
        })
	};


    const handleChangeLogin = name => event => {
        setLoginValues({ ...loginValues, [name]: event.target.value});
    };

    const handleLogin = (e) => {
        e.preventDefault();
		setLoading('login');
        setErrorCheck("login");
		if (!checkPhone(loginValues.phone) || !loginValues?.pass) {
			setLoading(false);
			setMessage('Заполните, пожалуйста, все поля');
			return;
		}

        logIn(loginValues?.phone, loginValues?.pass)
        .then((data)=>{
            if (data.error === true) {
                setMessage(data.error_description.slice(7, ));
                setLoading('')
                return
            }
            props.setToken(data.data?.access_token);
		    props.setUser(data.data);
            // window.localStorage.removeItem('orderFields'); // Нужно для проставления данных в окне заказа
            props.setIsRegister(false)
        })
        .catch (async(e)=>{
            const response = await e.res.json();
            setMessage(response.error_description.slice(7, ));   
        })
        .finally(()=>{
            setLoading('')
        })
	}

    const handleRecover = (e) => {
        e.preventDefault();
		setLoading('rec');
        setErrorCheck("rec");
		if (!checkPhone(loginValues.phone)) {	
			setLoading('');
			setMessage('Укажите, пожалуйста, номер телефона');
			return;
		}
		const captcha = recaptcha_ref1.current;
		if (!captcha?.getValue()) {
			setLoading('');
			setMessage('Пройдите, пожалуйста, капчу');
			return;
		}

        recover(loginValues?.phone, recaptcha_ref1.current.getValue())
        .then(()=>{
            setSuccess('Новый пароль отправлен вам по СМС');
        })
        .catch (async(e)=>{
            const response = await e.res.json();
            setMessage(response.error_description.slice(7, ));   
        })
        .finally(()=>{
            setLoading('');
        })
	}


    const handleAddCard = async () => {
		setLoading('addCard');
		let sign_for_binding;
        bindCard(props.user?.access_token)
        .then((data)=>{
            sign_for_binding = data.data
        })
        .then(()=>{

            const cart = [{
                name: "Привязка карты для пользователя " + props.user?.id,
                price: "1.00",
                quantity: "1",
                sum: "1.00",
                tax: "none",
            }];
            const pkform = document.getElementById('pkform'); //TODO: исправить говнокод на нормальный
            pkform[0].value = 1; // sum
            pkform[1].value = props.user?.id; // clientId
            pkform[3].value = "binding"; // service_name
            pkform[4].value = sign_for_binding; // sign
            pkform[5].value = JSON.stringify(cart); // cart
            pkform[6].value = 'createbinding'; // msgtype

            setTimeout(() => pkform.submit(), 500);
            
        })
        .catch (async(e)=>{
            const response = await e.res.json();
            setMessage(response.error_description.slice(7, ));   
        })
        .finally(()=>{
            setLoading('');
        })
	};

	const handleDeleteCard = async () => {
		setLoading('delCard');
		deleteCard(props.user?.access_token, selectedCard)
		.then(()=>{
		    setSuccess("Карта успешно удалена!");
		    getUserInfo(props.user?.access_token)
            .then((data)=>{
                props.setUser(data.data);
		        setSelectedCard(0);
            })
            .catch (async(e)=>{
                const response = await e.res.json();
                setMessage(response.error_description.slice(7, ));   
            })
        })
        .catch (async(e)=>{
            const response = await e.res.json();
            setMessage(response.error_description.slice(7, ));   
        })
        .finally(()=>{
            setLoading('');
        })
		
	};

    const handleChangePass = async () => {
		setErrorCheck('newPass');
		if (newPass !== newPass2) {
			setMessage('Пароли не совпадают!');			
			return;
		}
		setLoading('newPass');
        changePass(props.user?.access_token, newPass)
        .then(()=>{
            setSuccess("Пароль успешно изменен!");
        })
        .catch (async(e)=>{
            const response = await e.res.json();
            setMessage(response.error_description.slice(7, ));
        })
        .finally(()=>{
            setLoading('');
        })
	};


    const handleChangeEditAddress = name => event => {
		if (name === 'id')
			setEditAddress(addresses.find(a => parseInt(a.id) === parseInt(event.target.value)));
		else
        	setEditAddress({ ...editAdress, [name]: event.target.value});
    };

    const handleChangeAddAddress = name => (event, action) => {
		switch (name) {
			case 'street':
				if (action?.action === 'clear')
					setAddAddress({...addAddress, [name]: ''});
                else
					setAddAddress({...addAddress, [name]: event.value});
                break;
        	default:
				setAddAddress({ ...addAddress, [name]: event.target.value});
				break;
		}
        
    };

	const handleAddAddress = async () => {
		setLoading("addAdress");
		setErrorCheck("addAdress");
		if (!addAddress?.title
			|| !addAddress?.place_id
			|| (parseInt(addAddress?.place_id) !== 1 && !addAddress?.street)
			|| !addAddress?.house
			|| !addAddress?.room) {
                setLoading('');
			setMessage("Заполните, пожалуйста, все поля");
			return;
		}
        
        addAddressToUser({...addAddress, access_token: props.user?.access_token})
        .then((data)=>{
            setSuccess("Адрес успешно добавлен!");
            // Запишем новые данные о пользователе
            getUserInfo(props.user?.access_token)
            .then((data)=>{
                props.setUser(data.data);
            })
            .catch (async(e)=>{
                const response = await e.res.json();
                setMessage(response.error_description.slice(7, ));   
            })
        })
        .catch (async(e)=>{
            const response = await e.res.json();
            setMessage(response.error_description.slice(7, ));   
        })
        .finally(()=>{
            setLoading('');
        })
	};

    React.useEffect(() => {
		// window.localStorage.setItem('registerValues', JSON.stringify(registerValues));
		const interval = setTimeout(() => {setMessage(''); setSuccess('')}, 5000);
        return () => clearInterval(interval);
	}, [registerValues, message, success]);

    return (
        <div 
        className={props.isRegister || props.isLkOpened ? 'popup popup_opened' : 'popup'} 
        onClick={(e)=>{if (e.target === document.getElementsByClassName('popup_opened')[0]) {props.setIsRegister(false); props.setIsLkOpened (false)}}}
        >
            { props.isLkOpened 
            ?
                <div className='lk-popup'>

                    <h3>Личный кабинет</h3>
                    <div className="lk-popup__tab-header">   	
                        <div onClick={() => setActiveTab(1)} className={activeTab === 1 ? "lk-popup__tab lk-popup__tab_active" : "lk-popup__tab"}>Основное</div>								                	
                        <div onClick={() => setActiveTab(2)} className={activeTab === 2 ? "lk-popup__tab lk-popup__tab_active" : "lk-popup__tab"}>Мои адреса</div>
                        <div onClick={() => setActiveTab(3)} className={activeTab === 3 ? "lk-popup__tab lk-popup__tab_active" : "lk-popup__tab"}>История заказов</div>
                    </div>
                    
                    {activeTab === 1 && 
                        <div className="lk-popup__main">
                            <div className="lk-popup__block">
                                <h4>Добрый день, {props.user?.name}!</h4>
                                <h4>На вашем счету</h4>
                                <p className="lk-popup__balance">{props.user?.balance} р.</p>
                                <p>Мы перешли на полностью новый сайт, однако иногда возможны кратковременные перебои в работе. При возникновении проблем, просим вас незамедлительно сообщать на почту: mt@veryfood.ru</p>
                            </div>

                            <div className="lk-popup__block">

                                <div className=''>
                                    <h4>Ваши карты</h4>
                                    <select className="lk-popup__form-input" value={selectedCard} onChange={e => setSelectedCard(e.target.value)}>
                                        <option value={0} disabled="disabled">Выберите карту</option>
                                        {cards.map(c => <option key={c.id} value={c.id}>{c.card_number}</option>)}
                                    </select>
                                    <button className="lk-popup__button" onClick={handleAddCard} disabled={loading === 'addCard'}>{loading === 'addCard' ? "Загрузка..." : "Добавить новую карту"}</button>
                                    {selectedCard !== 0 &&
                                        <button className="lk-popup__button" onClick={handleDeleteCard} disabled={loading === 'delCard'}>{loading === 'delCard' ? "Загрузка..." : "Удалить карту"}</button>
                                    }
                                </div>

                                <div>
                                    <h4>Сменить пароль</h4>
                                    <input className={need_check_errors === "newPass" && !newPass ? 'lk-popup__form-input lk-popup__form-input_error' : 'lk-popup__form-input'} type='password' name='' placeholder='Новый пароль' value={newPass} onChange={e => setNewPass(e.target.value)}></input>
                                    <input className={need_check_errors === "newPass" && !newPass2 ? 'lk-popup__form-input lk-popup__form-input_error' : 'lk-popup__form-input'} type='password' name='' placeholder='Новый пароль ещё раз' value={newPass2} onChange={e => setNewPass2(e.target.value)}></input>

                                    <button className="lk-popup__button" onClick={handleChangePass} disabled={loading === 'newPass'}>{loading === 'newPass' ? "Загрузка..." : "Сохранить"}</button>
                                </div>

                            </div>
                        </div>
                    } {/*TODO: смена имени, пароля, добавление и удаление карт*/}

                    {activeTab === 2 &&
                        <div className="lk-popup__main">
                        
                            <div className="lk-popup__block">
                                <h4>Мои адреса</h4>
                                <select className="lk-popup__form-input" onChange={handleChangeEditAddress('id')} value={editAdress?.id}>
                                    <option disabled="disabled" value="0">Выберите адрес</option>
                                    {addresses.map(a => <option key={a.id} value={a.id}>{a.title}</option>)}
                                </select>
                                {parseInt(editAdress?.id) !== 0 && // TODO: добавить изменение адреса
                                <p className="error-message">
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true" />
                                    К сожалению, в данный момент изменение адреса находится в разработке
                                </p>
                                // <>
                                // 	<select value={addresses.find(a => parseInt(a.id) === parseInt(editAdress?.id))?.place_id} onChange={handleChangeEditAddress('place_id')}>
                                // 		{PLACES.map(p => {
                                // 			return <option key={p.id} value={p.id}>{p.title}</option>;
                                // 		})}
                                // 	</select>
                                // 	{parseInt(editAdress?.place_id) === 1
                                // 	?
                                // 	<>
                                // 		<input type="text" className="street" placeholder="Улица*" value="кампус" readOnly={true} />
                                // 		<select className="mipt" defaultValue={editAdress?.house} onChange={handleChangeEditAddress('house')}>
                                // 			{MIPT.map(p => {
                                // 				return <option key={p.id} value={p.title}>{p.title}</option>;
                                // 			})}
                                // 		</select>
                                // 		<input type="text" className="room" placeholder="Комната/Кабинет*" value={editAdress?.room} onChange={handleChangeEditAddress('room')}/>
                                // 	</>
                                // 	:
                                // 	<>
                                // 		<input type="text" className="street" placeholder="Улица*" value={editAdress?.street} onChange={handleChangeEditAddress('street')}/>
                                // 		<input type="text" className="house" placeholder="Дом/Корпус*" value={editAdress?.house} onChange={handleChangeEditAddress('house')}/>
                                // 		<input type="text" className="room" placeholder="Квартира/Офис*" value={editAdress?.room} onChange={handleChangeEditAddress('room')}/>
                                // 	</>
                                // 	}
                                // 	<input type="text" className="address-name" placeholder="Новое название (не обязательно)" value={editAdress?.new_name} onChange={handleChangeEditAddress('new_name')}/>
                                // 	<button className="make button" onClick={handleEditAddress} disabled={editing}>{editing ? <div className="loader">Сохранение...</div> : "Сохранить"}</button>
                                // 	{message.container === "edit" &&
                                // 		(message.type === "error" ?
                                // 			<p className="error-message">
                                // 				<i className="fa fa-exclamation-triangle" aria-hidden="true" />
                                // 				{message.text}
                                // 			</p>
                                // 		:
                                // 			<p className="success-message">
                                // 				<i className="fa fa-check" aria-hidden="true" />
                                // 				{message.text}
                                // 			</p>
                                // 		)
                                // 	}
                                // </>
                                }
                            </div>
                        
                            <div className="lk-popup__block">
                                <h4>Добавить адрес</h4>
                                <input type="text" className={need_check_errors === "addAdress" && !addAddress?.title ? 'lk-popup__form-input lk-popup__form-input_error' : 'lk-popup__form-input'} placeholder="Название адреса*" value={addAddress?.title} onChange={handleChangeAddAddress('title')}/>

                                <input type="text" className="lk-popup__form-input" placeholder="Город*" value="МФТИ" readOnly={true} />

                                {/* <select className={need_check_errors === "addAdress" && !addAddress?.place_id ? 'lk-popup__form-input lk-popup__form-input_error' : 'lk-popup__form-input'} value='1' disabled onChange={handleChangeAddAddress('place_id')}>
                                    <option disabled="disabled" value="1">МФТИ</option>
                                    {PLACES.map(p => {
                                        return <option key={p.id} value={p.id}>{p.title}</option>;
                                    })}
                                </select> */}

                                <>
                                    <input type="text" className="lk-popup__form-input" placeholder="Улица*" value="кампус" readOnly={true} />
                                    <select className={need_check_errors === "addAdress" && !addAddress?.house ? 'lk-popup__form-input lk-popup__form-input_small lk-popup__form-input_error' : 'lk-popup__form-input lk-popup__form-input_small'} value={addAddress?.house} onChange={handleChangeAddAddress('house')}>
                                        <option disabled="disabled" value="">Ваш корпус</option>
                                        {MIPT.map(p => {
                                            return <option key={p.id} value={p.title}>{p.title}</option>;
                                        })}
                                    </select>
                                    <input type="text" className={need_check_errors === "addAdress" && !addAddress?.room ? 'lk-popup__form-input lk-popup__form-input_small lk-popup__form-input_error' : 'lk-popup__form-input lk-popup__form-input_small'} placeholder="Комната/Кабинет*" value={addAddress?.room} onChange={handleChangeAddAddress('room')}/>
                                </>

                                {/* {parseInt(addAddress?.place_id) === 1
                                ?
                                <>
                                    <input type="text" className="lk-popup__form-input" placeholder="Улица*" value="кампус" readOnly={true} />
                                    <select className={need_check_errors === "addAdress" && !addAddress?.house ? 'lk-popup__form-input lk-popup__form-input_small lk-popup__form-input_error' : 'lk-popup__form-input lk-popup__form-input_small'} value={addAddress?.house} onChange={handleChangeAddAddress('house')}>
                                        <option disabled="disabled" value="">Ваш корпус</option>
                                        {MIPT.map(p => {
                                            return <option key={p.id} value={p.title}>{p.title}</option>;
                                        })}
                                    </select>
                                    <input type="text" className={need_check_errors === "addAdress" && !addAddress?.room ? 'lk-popup__form-input lk-popup__form-input_small lk-popup__form-input_error' : 'lk-popup__form-input lk-popup__form-input_small'} placeholder="Комната/Кабинет*" value={addAddress?.room} onChange={handleChangeAddAddress('room')}/>
                                </>
                                :
                                <>
                                    <CreatableSelect className='street' classNamePrefix={need_check_errors === "addAdress" && !addAddress?.street?.length ? 'street_error' : 'street'} options={PLACES.map(p => p.streets).flat().map(s => ({value: s, label: s}))}  placeholder="Улица*" isClearable={true} formatCreateLabel={(s) => ('Выбрать "' + s + '"')} defaultValue={addAddress?.street && {value: addAddress?.street, label: addAddress?.street}} onChange={handleChangeAddAddress("street")} />
                                    <input type="text" className={need_check_errors === "addAdress" && !addAddress?.house ? 'lk-popup__form-input lk-popup__form-input_small lk-popup__form-input_error' : 'lk-popup__form-input lk-popup__form-input_small'} placeholder="Дом/Корпус*" value={addAddress?.house} onChange={handleChangeAddAddress('house')}/>
                                    <input type="text" className={need_check_errors === "addAdress" && !addAddress?.room ? 'lk-popup__form-input lk-popup__form-input_small lk-popup__form-input_error' : 'lk-popup__form-input lk-popup__form-input_small'} placeholder="Квартира/Офис*" value={addAddress?.room} onChange={handleChangeAddAddress('room')}/>
                                </>
                                } */}

                                <button className="lk-popup__button" onClick={handleAddAddress} disabled={loading === "addAdress"}>{loading === "addAdress" ? "Сохранение..." : "Добавить"}</button>
                            </div>
                        </div>	      
                    }

                    {activeTab === 3 &&
                    <div>
                        <p>Данный раздел находится в разработке </p>
                    </div>
                    }

                    {message.length ? <p className="lk-popup__error-message"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {message}</p> : null}
                    {success.length ? <p className="lk-popup__success-message"><i className="fa fa-check" aria-hidden="true"></i> {success}</p> : null}

                    <i className="fa fa-times popup__close" aria-hidden="true" onClick={()=>{props.setIsLkOpened(false)}}></i>
                </div>  
            : props.isRegister
            ?
            <div className="lk-popup">
                { isRecoveryVisible 
                ?
                <form className='lk-popup__form' onSubmit={(e)=>{handleRecover(e)}}>
                    <h2 className='lk-popup__form-title'>ВОССТАНОВЛЕНИЕ ПАРОЛЯ</h2>
                    <InputMask type="tel" className={need_check_errors === 'rec' && !checkPhone(loginValues?.phone) ? 'lk-popup__form-input lk-popup__form-input_error' : 'lk-popup__form-input'} placeholder="Ваш телефон" mask="+7 999 999-99-99" maskChar="_" value={loginValues?.phone} onChange={handleChangeLogin('phone')} />
                    <div className="lk-popup__form-promocaptcha">
                        <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY} hl="ru" ref={recaptcha_ref1} />
                    </div>
                    <button className='lk-popup__form-login lk-popup__form-login_recovery' type='submit'>{loading === "rec" ? "Восстановление..." : "Получить новый пароль"}</button>
                    <p className='lk-popup__form-pass-recovery' onClick={() => setIsRecoveryVisible(false)}>Назад</p>
                </form>
                :
                <form className='lk-popup__form' onSubmit={(e)=>{handleLogin(e)}}>
                    <h2 className='lk-popup__form-title'>ВХОД</h2>
                    <InputMask type="tel" className={need_check_errors === "login" && !checkPhone(loginValues?.phone) ? 'lk-popup__form-input lk-popup__form-input_error' : 'lk-popup__form-input'} placeholder="Ваш телефон*" mask="+7 999 999-99-99" maskChar="_" value={loginValues?.phone} onChange={handleChangeLogin('phone')} />
                    <input className={need_check_errors === "login" && !loginValues?.pass ? 'lk-popup__form-input lk-popup__form-input_error' : 'lk-popup__form-input'} type='password' name='' placeholder='Пароль' value={loginValues?.pass} onChange={handleChangeLogin('pass')}></input>
                    <button className='lk-popup__form-login' type='submit' disabled={loading === "login"}>{loading === "login" ? "Авторизация..." : "Вход"}</button>
                    <p className='lk-popup__form-pass-recovery' onClick={() => setIsRecoveryVisible(true)}>Забыли пароль?</p>
                </form>
                }

                <form className='lk-popup__form' onSubmit={(e)=>{handleRegister(e)}}>
                    <h2 className='lk-popup__form-title'>РЕГИСТРАЦИЯ</h2>
                    <InputMask type="tel" className={need_check_errors === "reg" && !checkPhone(registerValues?.phone) ? 'lk-popup__form-input lk-popup__form-input_error' : 'lk-popup__form-input'} placeholder="Ваш телефон*" mask="+7 999 999-99-99" maskChar="_" value={registerValues?.phone} onChange={handleChangeRegister('phone')} />
                    <input type="text" className={need_check_errors === "reg" && !registerValues?.name ? 'lk-popup__form-input lk-popup__form-input_error' : 'lk-popup__form-input'} placeholder="Ваше имя*" value={registerValues?.name} onChange={handleChangeRegister('name')} />
                    
                    <h3 className="lk-popup__form-address-title">Основной адрес:</h3>
                    <select className="lk-popup__form-input" defaultValue={PLACES.find(p => p.id === Number(place_id))?.title ?? ''} onChange={handleChangeRegister('place_title')}>
                        <option value="" disabled="disabled">Выберите город</option>
                        {PLACES.map(p => <option key={p.id} value={p.title}>{p.title}</option>)}
                    </select>

                    {registerValues?.place_title === "МФТИ" 
                    ?
                        <input className="lk-popup__form-input" readOnly={true} value="кампус" /> 
                    :
                        <CreatableSelect className='street' classNamePrefix={need_check_errors === "reg" && !registerValues?.street?.length ? 'street_error' : 'street'} options={PLACES.map(p => p.streets).flat().map(s => ({value: s, label: s}))} placeholder="Улица*" isClearable={true} formatCreateLabel={(s) => ('Выбрать "' + s + '"')} defaultValue={registerValues?.street && {value: registerValues?.street, label: registerValues?.street}} onChange={handleChangeRegister("street")} />
                    }

                    {registerValues?.place_title === "МФТИ" 
                    ?
                        <select className={need_check_errors === "reg" && !registerValues?.house ? 'lk-popup__form-input lk-popup__form-input_small lk-popup__form-input_error' : 'lk-popup__form-input lk-popup__form-input_small'} defaultValue={registerValues?.house} onChange={handleChangeRegister('house')}>
                            <option value="" disabled="disabled">Ваш корпус</option>
                            {MIPT.map(k=>{return <option key={k.id}>{k.title}</option>})}
                        </select> 
                    :
                        <input type="text" className={need_check_errors === "reg" && !registerValues?.house ? 'lk-popup__form-input lk-popup__form-input_small lk-popup__form-input_error' : 'lk-popup__form-input lk-popup__form-input_small'} placeholder="Дом/Корпус*" value={registerValues?.house} onChange={handleChangeRegister('house')} />
                    }

                    <input type="text" className={need_check_errors === "reg" && !registerValues?.room ? 'lk-popup__form-input lk-popup__form-input_small lk-popup__form-input_error' : 'lk-popup__form-input lk-popup__form-input_small'} placeholder="Квартира/Офис*" value={registerValues?.room} onChange={handleChangeRegister('room')} />

                    <label className="lk-popup__form-checkbox-label">
                        <input className="lk-popup__form-checkbox" type="checkbox" name="order-checkbox" value="" checked={registerValues?.offer_checked} onChange={handleChangeRegister('offer_checked')}></input>
                        <span className="lk-popup__form-visible-checkbox"></span>
                        Я соглашаюсь с условиями оферты
                    </label>
                    
                    <div className="lk-popup__form-promocaptcha">
                        <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY} hl="ru" ref={recaptcha_ref2} />
                    </div>

                    <button type='submit' className="lk-popup__form-register" disabled={loading === 'reg'}>
                        {loading === 'reg' ? "Регистрация..." : "Зарегистрировать"}
                    </button>
	
                </form>

                    {message.length ? <p className="lk-popup__error-message"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {message}</p> : null}
                    {success.length ? <p className="lk-popup__success-message"><i className="fa fa-check" aria-hidden="true"></i> {success}</p> : null}

                <i className="fa fa-times popup__close" aria-hidden="true" onClick={()=>{props.setIsRegister(false)}}></i>

                <p className="lk-popup__note">
                    Внимание! Поля помеченые звездочкой, обязательны к заполнению<br/>
                    <i className="fa fa-lock" aria-hidden="true" /> Мы гарантируем безопасность Ваших данных.
				</p>     
            
            </div>
            :
            <></>
            }
        </div>
    );
}
    
export default LkPopup;