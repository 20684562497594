import { getCookie, PLACES } from './Consts'

export function getActiveProductsForCurrentPlace() {
    const place_id = 7; // для донера всегда 7 
    return fetch(`/api/api/products/active/byplace/${place_id ? place_id : 1}`, {})
    .then((res)=>{
        if(res.ok){
            return res.json()
          }
          return Promise.reject({massage: 'Ошибка на сервере', res})
    })
}

export function getSections() {
    return fetch('/api/api/crm/sections', {})
    .then((res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: 'Ошибка на сервере', res})
    })
}

export function makeOrder(values) {
    return fetch('/api/api/orders/', {
        method: 'POST',
        body: JSON.stringify({
            place_id: values?.place_id,
            phone: values?.phone,
            name: values?.name,
            address: values?.address,
            comment: values?.comment,
            chart: values?.cart, //TODO: исправить на беке опечатку
            promocode: values?.promocode,
            user_id: values?.user_id,
            pay_method: values?.pay_method,
            card_id: values?.card_id === 'new' ? null : values?.card_id,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
    })
    .then((res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: 'Ошибка на сервере', res})
    })
}

export function checkPromocode(promocode, captcha) {
    return fetch(`/api/api/promocodes/${promocode}?grecaptcha_response=${captcha}`, {})
    .then((res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: 'Ошибка на сервере', res})
    })
}

export function getOrder(order_id) {
    return fetch(`/api/api/orders/${order_id}`, {})
    .then((res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: 'Ошибка на сервере', res})
    })
}

export function sendReview(values) {
    return fetch('/api/api/reviews', {
        method: 'POST',
        body: JSON.stringify({
            name: values?.name,
            text: values?.text,
            place: values?.place,
            order_id: values?.order_id,
            email: values?.email,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
    })
    .then((res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: 'Ошибка на сервере', res})
    })
}

export function register(values) {
    const place = PLACES.find(p => p.title === values?.place_title);
    return fetch('/api/api/public_users/', {
        method: 'POST',
        body: JSON.stringify({
            phone: values?.phone,
            name: values?.name,
            address: {
                place_id: place?.id,
                street: place?.id === 1 ? 'кампус' : values?.street,
                house: values?.house,
                room: values?.room,
            },
            grecaptcha: values?.grecaptcha,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
    })
    .then(async(res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: res.error_description, res})
    })
}

export function logIn(phone, pass) {
    return fetch('/api/api/public_users/signin', {
        method: 'PUT',
        body: JSON.stringify({
            phone: phone,
            pass: pass,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
    })
    .then(async(res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: res.error_description, res})
    })
}

export function signOut(access_token) {
    return fetch('/api/api/public_users/signout', {
        method: 'PUT',
        body: JSON.stringify({
            access_token: access_token,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
    })
    .then(async(res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: res.error_description, res})
    })
}

export function recover(phone, grecaptcha_response) {
    return fetch('/api/api/public_users/pass_recovery', {
        method: 'PUT',
        body: JSON.stringify({
            phone: phone,
            grecaptcha_response: grecaptcha_response
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
    })
    .then(async(res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: res.error_description, res})
    })
}

export function getUserInfo(access_token) {
    return fetch(`/api/api/public_users/?access_token=${access_token}`, {})
    .then((res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: 'Ошибка на сервере', res})
    })
}

export function bindCard(access_token) {
    return fetch('/api/api/public_users/cards/bind', {
        method: 'POST',
        body: JSON.stringify({
            access_token: access_token,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
    })
    .then(async(res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: res.error_description, res})
    })
}

export function deleteCard(access_token, card_id) {
    return fetch('/api/api/public_users/cards/delete', {
        method: 'POST',
        body: JSON.stringify({
            access_token: access_token,
            card_id: card_id,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
    })
    .then(async(res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: res.error_description, res})
    })
}

export function changePass(access_token, new_pass) {
    return fetch('/api/api/public_users/pass_change', {
        method: 'PUT',
        body: JSON.stringify({
            access_token: access_token,
            new_pass: new_pass,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
    })
    .then(async(res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: res.error_description, res})
    })
}

export function addAddressToUser(values) {
    return fetch('/api/api/public_users/addresses', {
        method: 'POST',
        body: JSON.stringify({
            access_token: values?.access_token,
            title: values?.title,
            place_id: values?.place_id,
            street: parseInt(values?.place_id) === 1 ? 'кампус' : values?.street,
            house: values?.house,
            room: values?.room
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
    })
    .then(async(res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: res.error_description, res})
    })
}

export function getDashBoard() {
    return fetch(`/api/api/orders/public/dashboard`, {})
    .then((res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: 'Ошибка на сервере', res})
    })
}

export function getEmployees() {
    return fetch(`/api/api/employees/today/public`, {})
    .then((res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: 'Ошибка на сервере', res})
    })
}

export function getInfo() {
    return fetch(`/api/api/front/info`, {})
    .then((res)=>{
        if(res.ok){
            return res.json()
            }
            return Promise.reject({massage: 'Ошибка на сервере', res})
    })
}
