import React from 'react';
import face from "../images/saddness-face.png"
import ItemPopup from "./ItemPopup.js";
import OrderPopup from "./OrderPopup";
import ComplexPopup from './ComplexPopup';
import {Helmet} from "react-helmet";
import {getActiveProductsForCurrentPlace, getSections, getOrder} from '../utils/Api.js'
import { getCookie } from '../utils/Consts'

function Food(props) {

    const [basket, setBasket] = React.useState(JSON.parse(window.localStorage.getItem('basket')) ?? []);
    const [item, setItem] = React.useState('');
    const [sum, setSum] = React.useState(0);
    const [isOrder, setIsOrder] = React.useState(false);
    const [isOpenBasket, setIsOpenBasket] = React.useState(false);
    const [products, setProducts] = React.useState([]);
    const [sections, setSections ] = React.useState([]);
    const [activeSection, setActiveSection ] = React.useState(0);
    const [visibleProducts, setVisibleProducts ] = React.useState([]);
    const [orderID, setOrderID] = React.useState(getCookie('order_id'));
    const [orderInfo, setOrderInfo] = React.useState('');
    const [conflictBasket, setConflictBasket] = React.useState([]);
    const [isComplex, setIsComplex] = React.useState(false);

    function newSum(){
        let newsum = 0
        basket.forEach((buy)=>{
            newsum = newsum + Math.round(buy.item.price.value*buy.item.multiplier*buy.x)
        })
        setSum(newsum)
    }

    function checkbasket(item){
        return basket.find(function (basketItem) {
            return basketItem.item.id === item.id
        })
    }

    function addItem(item) {

        if (checkbasket(item)) {
            addPortion(checkbasket(item))
        }
        else {
            setBasket([...basket, 
                {
                    item: item,
                    x: 1,
                },
            ])
        }

        if(orderID){
            document.cookie = `order_id=;expires=${new Date(0)}`
            setOrderID('')
            window.localStorage.removeItem('paykeeper');
            window.localStorage.removeItem('prettyCart');
            window.localStorage.removeItem('createbinding');
        }
        
    }

    function deleteItem(item) {
        setBasket(basket.filter((item2)=>{
            return item.item.id !== item2.item.id
        }))
        
    }

    function addPortion(item) { 
        item.x = item.x+1;
        newSum()
    }

    function removePortion(item) {
        if(item.x === 1) {
            deleteItem(item);
        } else {
            item.x = item.x-1;
            newSum()
        };
    }

    function changeSection (id) {
        setActiveSection(id);
        const _products = products.filter(p => p.sections_id === id);
        setVisibleProducts(_products);
        if (id === 0) // Все блюда
            setVisibleProducts(products);
        if (id === -1) // Другое
            setVisibleProducts(products.filter(p => p.sections_id !== sections.find(s => s.id === p.sections_id)?.id));
            // На самом деле тут все просто, проходимся по всем товарам, находим те, чьи sections_id не в массиве sections
    };

    function checkProducts (prod) {

        const basket = JSON.parse(window.localStorage.getItem('basket'))

        if(!basket.every(function (basket_item) {
            return prod.some(function (plase_item) {
                return plase_item.id === basket_item.item.id;
            })
        })){
            setConflictBasket(
                basket.filter(function (basket_item) {
                    return !prod.some(function (new_item) {
                        return new_item.id === basket_item.item.id;
                    })
            }))
            setBasket(
                basket.filter(function (basket_item) {
                    return prod.some(function (new_item) {
                        return new_item.id === basket_item.item.id;
                    })
            }))
        }

    }

    React.useEffect(() => {
        getActiveProductsForCurrentPlace()
        .then(data => {
          setProducts(data.data);
          setVisibleProducts(data.data);
          checkProducts(data.data)

            // console.log(data.data.find((f)=>{return f.big_picture !== null}));

        })

        getSections().then(data => {
            setSections(data.data.slice(11,16).concat(data.data.slice(7,8))); // Берём только секции донера, напитки(8 секция) добавим отдельно
        })

        if(orderID) {
            getOrder(orderID).then(data => {
                setOrderInfo(data.data)

                if (data.data?.pay_method === "paykeeper" && data.data?.was_paid === 0) {

                    const paykeeper = JSON.parse(window.localStorage.getItem('paykeeper'))
                    const prettyCart = JSON.parse(window.localStorage.getItem('prettyCart'))
                    const createbinding = window.localStorage.getItem('createbinding')

                    const pkform = document.getElementById('pkform'); //TODO: исправить говнокод на нормальный
                    pkform.elements[0].value = paykeeper.sum; // sum;
                    pkform.elements[1].value = paykeeper.clientid; // clientId
                    pkform.elements[2].value = paykeeper.orderid; // orderId
                    pkform.elements[3].value = paykeeper.service_name; // service_name
                    pkform.elements[4].value = paykeeper.sign; // sign
                    pkform.elements[5].value = JSON.stringify(prettyCart); // cart
                    pkform.elements[6].value = 'createbinding'; // msgtype
                    if (createbinding) {
                        pkform.elements[6].value = createbinding; // msgtype
                    }
        
                }
                
            })
        }

        setInterval(function() {
            getActiveProductsForCurrentPlace()
            .then(data => {
                checkProducts (data.data)
            })
        }, 1000*60*5)

      }, []);

    React.useEffect(() => {
        newSum()
    }, [basket, item]);

    React.useEffect(() => {
        window.localStorage.setItem('basket', JSON.stringify(basket));
    }, [sum]);


    return (
        <section className="food">

            <Helmet>
                <title>MrDoner - Главная, все блюда.</title>
                <meta name="keywords" content="Меню, заказ, доставка"/>
                <meta name="description" content="Меню"/>
            </Helmet>

            <div className='sections-menu'>
                <div className='sections-menu__container'>
                    <p className={activeSection === 0 ? 'section section_active' : 'section'} onClick={() => changeSection(0)}>Все блюда</p>
                    {
                        sections.map(s => {
                            return (
                                <p className={activeSection === s.id ? 'section section_active' : 'section'} onClick={() => changeSection(s.id)} key={s.id}>{s.title}</p>
                            );
                        })
                    }
                    <p className={activeSection === -1 ? 'section section_active' : 'section'} onClick={() => changeSection(-1)}>Другое</p>

                </div>
            </div>

            <div className="food__container">
                <div className="food__menu">
                    
                    {visibleProducts.length ? visibleProducts.sort((a, b) => (b.order.value - a.order.value)).map((item)=>{
                        return (
                            <div className="food__item" key={item.id}>
                                <img className="food__item-image" alt={item.title} src={`./images/products/preview/${item.picture}`} onError={(e) => {e.target.onerror = null; e.target.src = './images/products/preview/1669889503118.jpeg'}} onClick={()=>{setItem(item);}}></img>
                                <h2 className="food__item-name">{item.title}</h2>
                                <div className="food__item-container">
                                    <button className="food__item-button" onClick={()=>{

                                        addItem(item)

                                        // item.title !== "Гречка"
                                        // ? addItem(item)
                                        // : setIsComplex(true)

                                    }}>Заказать</button>
                                    <div className="food__item-price-container">
                                        <p className={item.multiplier === 1 ? "food__item-price-discount" : "food__item-price-discount food__item-price-discount_active"}>{Math.round(item.price.value*item.multiplier)} ₽ </p>
                                        <p className={item.multiplier !== 1 ? "food__item-price" : "food__item-price food__item-price_active"}>{item.price.value} ₽</p>
                                    </div>
                                </div>
                                
                                <div className= { checkbasket(item) ? "food__item-x food__item-x_active" : "food__item-x"}>
                                    <button className="food__item-x-btn food__item-x-btn_remove" onClick={()=>{removePortion(basket.find((basketitem)=>{return basketitem.item.id === item.id}))}}>-</button>
                                    <p>{checkbasket(item)?.x}</p>
                                    <button className="food__item-x-btn food__item-x-btn_add" onClick={()=>{addPortion(basket.find((basketitem)=>{return basketitem.item.id === item.id}))}}>+</button>
                                </div>

                                <div className={item.multiplier < 1 ? "food__item-discount food__item-discount_active" : "food__item-discount"}><p>-{Math.round((1-item.multiplier)*100)}%</p></div>
                            </div>
                        )
                    })
                    :
                    <h1 className="food__void-text">У выбранной зоны доставки сейчас нет доступных блюд.</h1>
                    }
                    

                </div>
                <div className="food__basket">

                    <button className={isOpenBasket ? "food__basket-open-button food__basket-open-button_closed" : basket.length ? "food__basket-open-button food__basket-open-button_active_x" : orderID ? "food__basket-open-button food__basket-open-button_active_order" : "food__basket-open-button"} onClick={()=>{setIsOpenBasket(!isOpenBasket)}}>
                        <i className="fa fa-shopping-basket fa-3x" aria-hidden="true"></i>
                        <p className={basket.length ? "food__basket-x food__basket-x_active" : "food__basket-x"}>{basket.length}</p>
                        <p className={orderID ? "food__basket-order food__basket-order_active" : "food__basket-order"}>
                        {orderInfo.pay_method === "paykeeper" && !orderInfo.was_paid ? `Заказ ${orderInfo.id} ожидает оплаты` : `Заказ ${orderInfo.id}`}
                        </p>
                    </button>

                    <div className={isOpenBasket ? "food__basket-container food__basket-container_opened" : "food__basket-container"}>
                        { basket.length
                        ?
                        <>
                            <p className="food__basket-subtitle">ВАШ ЗАКАЗ</p>
                            <h2 className="food__basket-title">VERYFOOD.RU</h2>
                            <ol className="food__basket-list">

                                {basket.map((basketitem)=>{
                                    return (
                                        <li key={basketitem.id}>
                                            <div className="food__basket-item">
                                                <p className="food__basket-item-name" title={basketitem.item.title}>{basketitem.item.title}</p>
                                                <div className='food__basket-item-buttons'>
                                                    <button className='food__basket-item-button food__basket-item-button_remove' type='button' onClick={()=>{removePortion(basketitem)}}>-</button>
                                                    <p className='food__basket-item-x'>{basketitem.x}</p>
                                                    <button className='food__basket-item-button food__basket-item-button_add' type='button' onClick={()=>{addPortion(basketitem)}}>+</button>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                                
                            </ol>
                            <p className="food__basket-sum-text">ОБЩАЯ СУММА</p>
                            <p className="food__basket-sum">{sum} РУБ</p>
                            <button className="food__basket-order-button" onClick={()=>{setIsOrder(true)}}>Оформить заказ</button>
                        </>
                        : orderID 
                        ?
                        <div className="food__basket-order-info">
                            <h2 className="food__basket-title">VERYFOOD.RU</h2>
                            {orderInfo.status === "created" ? <div>ПОЗДРАВЛЯЕМ!<br/>Ваш заказ #<span className="food__basket-order-info-span">{orderInfo.id}</span> принят!<br/></div> : null}
                            {orderInfo.status === "ready" ? <div>Ваш заказ #<span className="food__basket-order-info-span">{orderInfo.id}</span><br/>Собран и ожидает курьера.</div> : null}
                            {orderInfo.status === "inaway" ? <div>Ваш заказ #<span className="food__basket-order-info-span">{orderInfo.id}</span><br/>Уже в пути.</div> : null}
                            {orderInfo.status === "delivered" ? <div>СПАСИБО!<br/>Ваш заказ #<span className="food__basket-order-info-span">{orderInfo.id}</span> доставлен!<br/></div> : null}
                            {orderInfo.status === "stoped" ? <div><br/>Ваш заказ #<span className="food__basket-order-info-span">{orderInfo.id}</span> был отменен :<br/></div> : null}
                            {orderInfo.pay_method === "cash" ? "Оплата наличными" : (orderInfo.pay_method === "paykeeper" ? (orderInfo.was_paid ? "Оплачен картой" : <a href="javascript:void(0)" onClick={()=>{document.getElementById('pkform').submit()}}>"Ожидает оплаты картой"</a>) : (orderInfo.pay_method == "bonus" ? "Оплата бонусами" : "Оплата картой курьеру"))}<br/>
                            Итоговая сумма заказа<br/><span className="food__basket-order-info-span">{Math.round(orderInfo.total_price*orderInfo.multiplier)} руб</span><br/>
                            {orderInfo.multiplier !== 1 ? <div>(с учетом скидки {Math.round((1-orderInfo.multiplier)*100)}%)</div> : null}
                            Подробнее о статусе <a href="/tracking/">ТУТ</a>
                        </div>
                        :
                        <>
                            <h2 className="food__basket-title">VERYFOOD.RU</h2>
                            <img className="food__basket-image" alt="Добавьте блюда в заказ" src={face}></img>
                            <p className="food__basket-text">ВАШ ЧЕК ПУСТ</p>
                            <p className="food__basket-text">ДОБАВЬТЕ В ЗАКАЗ</p>
                            <p className="food__basket-text">БЛЮДА ИЗ МЕНЮ</p>
                        </>
                        }

                        <i className="fa fa-times food__basket-close" aria-hidden="true" onClick={()=>{setIsOpenBasket(!isOpenBasket)}}></i>

                    </div>
                    <div className={isOpenBasket ? "food__basket-overlay food__basket-overlay_opened" : "food__basket-overlay"} onClick={()=>{setIsOpenBasket(!isOpenBasket)}}><div></div></div>

                </div>
            </div>

            <ItemPopup basket={basket} setBasket={setBasket} item={item} setItem={setItem} orderID={orderID} setOrderID={setOrderID} removePortion={removePortion} addPortion={addPortion} checkbasket={checkbasket}/>
            <OrderPopup isOrder={isOrder}  setIsOrder={setIsOrder} basket={basket} setBasket={setBasket}  setOrderID={setOrderID} setOrderInfo={setOrderInfo} user={props.user} setUser={props.setUser} info={props.info}/>
            <ComplexPopup isComplex={isComplex} setIsComplex={setIsComplex} products={products}/>

            <div className={conflictBasket.length ? "popup popup_opened" : "popup"}>
                <div className="popup__conflict">
                    <p>Следующие блюда были удалены из карзины, так как больше не доступны в выбранной зоне доставки.</p>
                    <ol className="">
                        {conflictBasket.map((basketitem)=>{
                            return (
                                <li key={basketitem.item.id}> {basketitem.item.title}</li>
                            )
                        })}
                    </ol>
                    <button className="popup__conflict-button" onClick={()=>{window.location.reload();}}>ОК</button>
                </div>
            </div>

        </section>
    );
}
    
export default Food;