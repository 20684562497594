import React, { useState } from 'react'

import { PLACES, getCookie } from '../utils/Consts.js'
import {getActiveProductsForCurrentPlace} from '../utils/Api.js'

const place_id = getCookie('place_id')

/**
 * Элемент выбора зоны доставки. Автоматически отображает форму выбора зоны доставки, если не установлен localStorage place_id
 * @param {*} props 
 */
function PlaceSelect(props) {

	const basket = JSON.parse(window.localStorage.getItem('basket'));

	const [modal_is_visible, setModalVisible] = useState(true);
	const [conflictBasket, setConflictBasket] = useState([]);

	const selectNewPlace = (conflictBasket)=>{
	
		if(conflictBasket.length ) {
			window.localStorage.setItem('basket', JSON.stringify(
				basket.filter(function (basket_item) {
					return !conflictBasket.some(function (conflict_item) {
						return conflict_item.item.id === basket_item.item.id;
					})
				})
			))
		}

		setModalVisible(false);
		window.location.reload();
	}


	const checkNewPlace = (new_place_id) => {

		let conflictBasket = []

		document.cookie = `place_id=${new_place_id};path=/;expires=${new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString()}`

		if(basket){ 
			getActiveProductsForCurrentPlace()
			.then(data => {
				if(
					basket.every(function (basket_item) {
						return data.data.some(function (plase_item) {
							return plase_item.id === basket_item.item.id;
						})
					})
				) {
					selectNewPlace(conflictBasket)
				} else {
					
					document.cookie = `place_id=${place_id};path=/;expires=${new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString()}`

					setConflictBasket(
						basket.filter(function (basket_item) {
							return !data.data.some(function (plase_item) {
								return plase_item.id === basket_item.item.id;
							})
					}))
				}
			})
		} else {
			selectNewPlace(conflictBasket)
		}
	}

	
	
	return <>
		<div className='header__nav-place-block' onClick={() => setModalVisible(false)}>
			<i className="fa fa-map-marker fa-lg header__nav-place-image" aria-hidden="true"></i>
			<p className='header__nav-place-text'>
				Мистер Донер
			</p>
		</div>
		{
			!modal_is_visible
				?
				<PlaceSelectModal place_id={place_id} onClose={() => {setModalVisible(true); setConflictBasket([])}} onCheck={checkNewPlace} onSelect={selectNewPlace} conflictBasket={conflictBasket}/>
				:
				null
		}
	</>
}

/**
 * Форма выбора зоны доставки
 * @param {
 * 	onSelect - функция вызываема при смене зоны доставки с аргементом - id зоны доставки (place_id)
 * 	onClose - функция вызывается когда форму необходимо закрыть
 * 	place_id - текущая зона доставки
 * } props 
 */
function PlaceSelectModal(props) {

	const [newPlaceID, setNewPlaceID] = useState('');

	const onCheck = place_id => () => {
		props.onCheck(place_id); 
		setNewPlaceID(place_id);
	} 

	return <div className="popup-plase-select" onClick={() => {
		if(props.place_id){
			props.onClose() 
		} 
		
	}}>
		<div className="place-select" onClick={(e) => e.stopPropagation()}>
			{props.place_id ? <span className="place-select-close" onClick={props.onClose}><i className="fa fa-times fa-lg" aria-hidden="true"></i></span> : ""}
			<h3 className='place-select-title'>Выберите свой город:</h3>
			<div className='place-select-groups'>
                
				<label htmlFor="_7" className="place-select-place">
                    <input type="radio" className="place-select-group-name" id="_7" checked={parseInt(props.place_id) === 7} readOnly onClick={onCheck(7)} />
                    МФТИ: МистерДонер
                </label>

                <label htmlFor="_7" className="place-select-place" onClick={()=>{window.location.href='https://test.veryfood.ru/'}}>
                    <input type="radio" className="place-select-group-name" id="_7" checked={parseInt(props.place_id) !== 7 } readOnly/>
                    МФТИ, Долгопрудный, Химки: VeryFood
                </label>
                
                {/* <label htmlFor="_2" className="place-select-place">
                    <input type="radio" className="place-select-group-name" id="_2" checked={parseInt(props.place_id) === 2} readOnly onClick={onCheck(2)}/>
                    Долгопрудный
                </label>
                
                <label htmlFor="_3" className="place-select-place">
                    <input type="radio" className="place-select-group-name" id="_3" checked={parseInt(props.place_id) === 3} readOnly onClick={onCheck(3)} />
                    Химки
                </label>
                
                <label htmlFor="_6" className="place-select-place">
                    <input type="radio" className="place-select-group-name" id="_6" checked={parseInt(props.place_id) === 6} readOnly onClick={onCheck(6)} />
                    Лобня
                </label> */}

			</div>
			
			{ props.conflictBasket.length 
			? 
			<div>
				<p>Следующие блюда будут удалены из карзины, так как отсутствуют в ввыбранной зоне доставки "{PLACES.find(f => f.id === parseInt(newPlaceID))?.title}".</p>
				<ol className="">
					{props.conflictBasket.map((basketitem)=>{
						return (
							<li key={basketitem.item.id}> {basketitem.item.title}</li>
						)
					})}
				</ol>

				<p>Поменять зону доставки?</p>
				<div className='place-select-buttons'>
					<button className='place-select-button' onClick={()=>{
						document.cookie = `place_id=${newPlaceID};path=/;expires=${new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString()}`
						props.onSelect(props.conflictBasket)
					}}>Да</button>
					<button className='place-select-button' disabled={!props.place_id} onClick={()=>{
						document.cookie = `place_id=${props.place_id ?? ''};path=/;expires=${new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString()}`;
						props.onClose();
					}}>Нет</button>
				</div>
			</div>
			:
			<></>
			}
			
		</div>
	</div>
}

export default PlaceSelect;