import React from 'react';
import ReCAPTCHA from "react-google-recaptcha"
import CreatableSelect from 'react-select/creatable'
import InputMask from 'react-input-mask'
import { PLACES, MIPT, checkPhone, getCookie, CAPTCHA_SITE_KEY } from '../utils/Consts'
import  { makeOrder, checkPromocode, getOrder, addAddressToUser, getUserInfo} from '../utils/Api'

function MIPTBuildings(props) {
    const { onChange, need_check_errors, house } = props;
    return <select
        className={need_check_errors && !house?.length ? 'popup__order-inuput popup__order-inuput_small popup__order-inuput_error' : 'popup__order-inuput popup__order-inuput_small'}
        onChange={onChange}
        defaultValue={MIPT.find(h => h.title === house)?.title ?? ''}
        disabled={props.disabled}
        >
            <option value="" disabled="disabled">Ваш корпус</option>
            {
                MIPT.map(b => <option key={b.id} value={b.title}>{b.title}</option>)
            }
    </select>
}

function OrderPopup(props) {
    const recaptcha_ref = React.createRef();
    const place = PLACES.find(p => p.id === 1);
    const streets = place?.streets?.map(s => ({value: s, label: s}));
    const [isOrderLoading, setIsOrderLoading] = React.useState(false);
    const [multiplier, setMultiplier] = React.useState(1);
    const [error_message, setErrorMessage] = React.useState("");
    const [need_check_errors, setErrorCheck] = React.useState(false);
    const [success, setSuccess] = React.useState("");
    const totalCost = props.basket.reduce((sum, p) => sum + Math.round(p.item.price.value*p.item.multiplier*p.x), 0);
    const saleable = props.basket.filter(p => p.item.saleable === 1 && p.item.multiplier === 1);
    const notSaleable = props.basket.filter(p => p.item.saleable !== 1 || p.item.multiplier !== 1);
    const [user, setUser] = React.useState(JSON.parse(window.localStorage.getItem('publicUser')));
    const payMethod = props.info.pay_method ?? "none"

    const [values, setValues] = React.useState(
        // JSON.parse(window.localStorage.getItem('orderFields')) ??
        {
            persons: 1,
            name: user?.name,
            phone: user?.phone,
            street: place ? user?.addresses?.find(a => a.place_id === place.id)?.street : '',
            house: place ? user?.addresses?.find(a => a.place_id === place.id)?.house : '',
            room: place ? user?.addresses?.find(a => a.place_id === place.id)?.room : '',
            comment: '',
            make_hot: false,
            promocode: '',
            i_have_promocode: false,
            need_change: false,
            user_address: place ? user?.addresses?.find(a => a.place_id === place.id)?.id : '',
            new_address_name: '',
            save_address: true,
            card_id: user?.cards?.at(0)?.id ?? 'new',
            save_card: true,
            pickup: false
    });

    const handleChange = name => (event, action) => {
            switch (name) {
                case 'user_address':
                    setValues({ ...values, [name]: event.target.value, ...user?.addresses?.find(a => parseInt(a.id) === parseInt(event.target.value))});
                    break;
                case 'street':
                    if (action?.action === 'clear')
                        setValues({...values, [name]: ''});
                    else
                        setValues({...values, [name]: event.value});
                    break;
                default:
                    setValues({ ...values, [name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value });
                    break;
            }
            
    };

    const handleMakeOrder = async (e) => {

        e.preventDefault();
        setErrorCheck(true);
        if (!values?.name
            || !checkPhone(values?.phone)
            || (place.id !== 1 && !values?.street)
            || (!values?.house && !values.pickup)
            || (!values?.room  && !values.pickup)
        ) {
            setErrorMessage('Пожалуйста, заполните все поля, отмеченные звёздочкой');
            return;
        }
        if (!values?.pay_method || (values?.pay_method === "cash" && values?.pickup)) {
            setErrorMessage('Пожалуйста, выберите способ оплаты');
            return;
        }
        // TODO: динамическая поддержка городов
        if (place?.id > 3 && place?.id !== 6) { // Только МФТИ, Долгопрудный, Химки и Лобня
            setErrorMessage('К сожалению, в этот город доставка не работает');
            return;
        }

        if (values?.pay_method === "paykeeper" && (props.info === 'default'|| !props.info || !props.info.pk_server)) { // Способ оплаты paykeeper и не получили от сервера платежную информацию
            setErrorMessage('К сожалению, данный способ оплаты сейчас не доступен');
            return;
        }
        // Все хорошо, оформляем заказ
        setIsOrderLoading(true);

        if (user && parseInt(values?.user_address) === 0 && values?.save_address) { // Сохраняем адрес
            addAddressToUser({...values, title: values?.new_address_name, place_id: String(place.id), access_token: user?.access_token})
            .then(()=>{
                getUserInfo(user?.access_token)
                .then((data)=>{
                    props.setUser(data.data);
                    setUser(data.data)
                })
                .catch (async(e)=>{
                    const response = await e.res.json();
                    setErrorMessage(response.error_description.slice(7, ));
                    return;
                })
            })
            .catch (async(e)=>{
                const response = await e.res.json();
                setErrorMessage(response.error_description.slice(7, ));
                return; 
            })
        }

        const prettyCartSaleable = saleable.reduce((acc, p) => {
            acc.push({...p, q: p.x})
            return acc
        }, []).map(p => ({
            id: p.item.id,
            quantity: p.q,
            name: p.item.title,
            multiplier: 1,
            saleable: 1,
            price: parseInt(p.item.price.value) * multiplier, /* TODO: whole */
            sum: parseInt(p.q) * parseInt(p.item.price.value) * multiplier, /* TODO: whole */
            tax: "none",
        }));
        const prettyCartNotSaleable = notSaleable.reduce((acc, p) => {
            acc.push({...p, q: p.x})
            return acc
        }, []).map(p => ({
            id: p.item.id,
            quantity: p.q,
            name: p.item.title,
            multiplier: parseFloat(p.item.multiplier),
            saleable: 0,
            price: parseInt(p.item.price.value) * parseFloat(p.item.multiplier), /* TODO: whole */
            sum: parseInt(p.q) * parseInt(p.item.price.value) * parseFloat(p.item.multiplier), /* TODO: whole */
            tax: "none",
        }));

        const prettyCart = prettyCartSaleable.concat(prettyCartNotSaleable);

        makeOrder({
            ...values,
            place_id: '7',
            address:  "МИСТЕР ДОНЕР, " + (values?.pickup ? "Самовывоз" : (values?.street || "кампус") + ", дом " + values?.house + ", кв/офис " + values?.room),
            comment: "Количество приборов: " + values?.persons + (values?.comment ? ", " + values?.comment : "") + (values?.need_change ? ", сдача с " + values?.alotofmoney : "") + (values?.make_hot ? ", подогреть." : ""),
            cart: prettyCart,
            user_id: String(user?.id ?? ""),
        })
        .then(data => {

            if(data.error) {
                setErrorMessage(data.error_description.substring(6))
                return
            }

            getOrder(data.data.order_id)
            .then(data => {
                props.setOrderInfo(data.data)
            })

            document.cookie = "order_id=" + data.data.order_id;
            props.setBasket([])
            props.setOrderID(data.data.order_id)
            props.setIsOrder(false)

            if (data.data?.pay_method === "paykeeper" && data.data?.was_paid === false) {
                const pkform = document.getElementById('pkform'); //TODO: исправить говнокод на нормальный
                pkform.elements[0].value = data.data.paykeeper.sum; // sum;
                pkform.elements[1].value = data.data.paykeeper.clientid; // clientId
                pkform.elements[2].value = data.data.paykeeper.orderid; // orderId
                pkform.elements[3].value = data.data.paykeeper.service_name; // service_name
                pkform.elements[4].value = data.data.paykeeper.sign; // sign
                pkform.elements[5].value = JSON.stringify(prettyCart); // cart
                if (values?.save_card) {
                    pkform.elements[6].value = 'createbinding'; // msgtype
                    window.localStorage.setItem('createbinding', 'createbinding');
                }

                window.localStorage.setItem('paykeeper', JSON.stringify(data.data.paykeeper));
                window.localStorage.setItem('prettyCart', JSON.stringify(prettyCart));

                setTimeout(() => pkform.submit(), 1000);
            }

        })
        .catch (e => {
            console.log(e);
            setErrorMessage(e.message.slice(7, ));
        })
        .finally (()=>{
            setIsOrderLoading(false);
        })
    }


    function applyPromo() {
        const captcha = recaptcha_ref.current;
        setErrorMessage("");
        setSuccess("");
        setMultiplier(1);
        if (captcha.getValue().length && values?.promocode?.length) {
            checkPromocode(values?.promocode, captcha.getValue())
                .then(ans => {
                    if (ans.data.status === "found") {
                        if (ans.data.active) {
                            if (ans.data.type === "whole")
                                setSuccess("Промокод успешно применен");
                            setMultiplier(ans.data.multiplier);
                        } else {
                            setErrorMessage("Промокод уже не действителен");
                        }
                    } else {
                        setErrorMessage("Такого промокода не существует, проверьте введенный код.")
                    }
                })
                .catch(msg => setErrorMessage(msg.toString()))
                .finally(() => captcha.reset())
        } else {
            setErrorMessage("Необходимо заполнить поле промокод и пройти капчу.")
        }
    }

    function countSumMultiplier() {
        return saleable.reduce((sum, p) => sum + Math.round(p.item.price.value*p.item.multiplier*p.x), 0) * multiplier + notSaleable.reduce((sum, p) => sum + Math.round(p.item.price.value*p.item.multiplier*p.x), 0)
    }


    React.useEffect(() => {
        // window.localStorage.setItem('orderFields', JSON.stringify(values));
        // window.localStorage.setItem('publicUser', JSON.stringify(user));
        const interval = setTimeout(() => setErrorMessage(''), 5000);
        return () => clearInterval(interval);
    }, [error_message])

    React.useEffect(() => {
        setTimeout(() => setUser(JSON.parse(window.localStorage.getItem('publicUser'))), 5000);
        setUser(JSON.parse(window.localStorage.getItem('publicUser')));
    }, [props.user])

    return (
        <div 
        className={props.isOrder ? 'popup popup_opened' : 'popup'} 
        onClick={(e)=>{if (e.target === document.getElementsByClassName('popup_opened')[0]) {props.setIsOrder(false)}}}
        >
            <div className="popup__order">
                <h2 className='popup__order-title'>ОФОРМЛЕНИЕ ЗАКАЗА</h2>
                <form className='popup__order-form' onSubmit={(e)=>{handleMakeOrder(e)}}>
                    <div className='popup__order-form-left'>

                        {
                            multiplier === 1
                            ?
                            <h3 className='popup__order-total'><span className='popup__order-total-span'>ИТОГО: </span>{totalCost}</h3>
                            :
                            <h3 className='popup__order-total'><span className='popup__order-total-span'>ИТОГО: </span><span className='popup__order-total-span-promo'>{totalCost}</span>{Math.round(countSumMultiplier())}</h3>
                            // countSumMultiplier() // не уверен правильно ли выводится скидка
                        }

                        {/* <h3 className='popup__order-total'><span className='popup__order-total-span'>ИТОГО: </span>
                        {
                            multiplier === 1
                            ?
                            totalCost
                            :
                            countSumMultiplier() // не уверен правильно ли выводится скидка
                        }
                        </h3> */}

                        <p className='popup__order-text'>Комплектов приборов</p>
                        <div className='popup__order-cutlery-block'>
                            <button className='popup__order-cutlery-button popup__order-cutlery-button_remove' type='button' onClick={() => setValues({ ...values, persons: values?.persons !== 0 ? values?.persons - 1 : 0 })}>-</button>
                            <p className='popup__order-cutlery'>{values?.persons}</p>
                            <button className='popup__order-cutlery-button popup__order-cutlery-button_add' type='button' onClick={() => setValues({ ...values, persons: values?.persons + 1 })}>+</button>
                        </div>
                        <input type="text" name="" className={need_check_errors && !values?.name?.length ? 'popup__order-inuput popup__order-inuput_error' : 'popup__order-inuput'} placeholder="Ваше имя*" value={values?.name} onChange={handleChange('name')}></input>
                        <InputMask type="tel" className={need_check_errors && !checkPhone(values?.phone) ? 'popup__order-inuput popup__order-inuput_error' : 'popup__order-inuput'} placeholder="Ваш номер телефона*" mask="+7 999 999-99-99" maskChar="_" value={values?.phone} onChange={handleChange("phone")} />
                        <p>Адрес доставки:</p>

                        <label className="popup__order-checkbox-label popup__order-checkbox-label_block">
                            <input className="popup__order-checkbox" type="checkbox" name="order-checkbox" value="" onChange={handleChange('pickup')} checked={values?.pickup}></input>
                            <span className="popup__order-visible-checkbox"></span>
                            Самовывоз
                        </label>

                        {values.pickup ? <p>Первомайская улица, 7А, Долгопрудный, Московская область, 141720</p> : null}

                        {user && (parseInt(values?.user_address) === 0 || values?.user_address === undefined || !values.pickup) && 
    
                            <label className="popup__order-checkbox-label popup__order-checkbox-label_block">
                                <input className="popup__order-checkbox" type="checkbox" name="order-checkbox" value="" checked={values?.save_address} onChange={handleChange('save_address')}></input>
                                <span className="popup__order-visible-checkbox"></span>
                                Сохранить адрес
                            </label>

                        }

                        {
                        user
                            ?
                            <>
                                <select className='popup__order-inuput popup__order-inuput_small' onChange={handleChange("user_address")} disabled={values.pickup}>
                                    {
                                       place ? user.addresses.map(a => a.place_id === place.id && <option key={a.id} value={a.id}>{a.title}</option>) : null
                                    }
                                    <option key={0} value={0}>Другой адрес</option>
                                </select>
                                {parseInt(values?.user_address) === 0 || values?.user_address === undefined ? 
                                    values?.save_address && <input type="text" name="" className="popup__order-inuput popup__order-inuput_small" placeholder="Название адреса*" value={values?.new_address_name} onChange={handleChange('new_address_name')} disabled={values.pickup}/>
                                    :
                                    place ? <input type="text" name="" className="popup__order-inuput popup__order-inuput_small" readOnly="yes" value={PLACES.find(p => p.id === place.id)?.title} /> : null
                                }
                            </>
                            :
                            <input type="text" name="" className="popup__order-inuput" readOnly="yes" value={place?.title}></input>
                        }

                        { user?.addresses?.find(a => a.id === parseInt(values.user_address))
                        ?
                            <>
                                <input type="text" name="" className="popup__order-inuput" placeholder="Улица*" value={user.addresses.find(a => a.id === parseInt(values.user_address)).street} readOnly={true} />
                                <input type="text" name="" className="popup__order-inuput popup__order-inuput_small" placeholder="Дом*" value={user.addresses.find(a => a.id === parseInt(values.user_address)).house} readOnly={true} disabled={values.pickup}/>
                                <input type="text" name="" className="popup__order-inuput popup__order-inuput_small" placeholder="Офис/Квартира*" value={user.addresses.find(a => a.id === parseInt(values.user_address)).room} readOnly={true} disabled={values.pickup}/>
                            </>
                        :
                        place?.id !== 1
                        ?
                            <>
                                <CreatableSelect className='street' classNamePrefix={need_check_errors && !values?.street?.length ? 'street_error' : 'street'} options={streets} placeholder="Улица*" isClearable={true} formatCreateLabel={(s) => ('Выбрать "' + s + '"')} defaultValue={values?.street && {value: values?.street, label: values?.street}} onChange={handleChange("street")} />
                                <input type="text" name="" className={need_check_errors && !values?.house?.length ? 'popup__order-inuput popup__order-inuput_small popup__order-inuput_error' : 'popup__order-inuput popup__order-inuput_small'} placeholder="Дом*" value={values?.house} onChange={handleChange("house")} />
                                <input type="text" name="" className={need_check_errors && !values?.room?.length ? 'popup__order-inuput popup__order-inuput_small popup__order-inuput_error' : 'popup__order-inuput popup__order-inuput_small'}  placeholder="Офис/Квартира*" value={values?.room} onChange={handleChange("room")} />
                            </>
                        :
                            <>
                                <input type="text" name="" className='popup__order-inuput' placeholder="Улица*" value={"кампус"} readOnly={true} />
                                <MIPTBuildings onChange={handleChange("house")} house={values?.house} need_check_errors={need_check_errors} disabled={values.pickup}/>
                                <input type="text" name="" className={need_check_errors && !values?.room?.length ? 'popup__order-inuput popup__order-inuput_small popup__order-inuput_error' : 'popup__order-inuput popup__order-inuput_small'} placeholder="Комната/Кабинет*" value={values?.room} onChange={handleChange("room")} disabled={values.pickup} />
                            </>
                        }

                        <textarea className="popup__order-inuput popup__order-inuput_textarea" placeholder="Комментарий" value={values?.comment} onChange={handleChange("comment")}></textarea>
                    </div>

                    <div className='popup__order-form-right'>
                        <label className="popup__order-checkbox-label">
                            <input className="popup__order-checkbox" type="checkbox" name="order-checkbox" value="" onChange={handleChange('make_hot')} checked={values?.make_hot}></input>
                            <span className="popup__order-visible-checkbox"></span>
                            Подогреть
                        </label>
                        <label className="popup__order-checkbox-label">
                            <input className="popup__order-checkbox" type="checkbox" name="order-checkbox" value="" onChange={handleChange('i_have_promocode')} checked={values?.i_have_promocode}></input>
                            <span className="popup__order-visible-checkbox"></span>
                            У меня есть промокод
                        </label>

                        { values?.i_have_promocode
                        ? 
                        <div className="popup__order-promobox">
                            <input type="text" name="" className="popup__order-inuput popup__order-inuput_promo" placeholder="Введите промокод" id="ihavepromocode_input" onChange={handleChange("promocode")} value={values?.promocode}/>
                            <button type='button' className="popup__order-promobox-button" onClick={applyPromo}>ПРИМЕНИТЬ</button>
                            <div className="popup__order-promocaptcha">
                                <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY} hl="ru" ref={recaptcha_ref} />
                            </div>
                            <p className='popup__order-promobox-text'>Скидка не распространяется на блюда со скидкой</p>
                        </div>
                        :
                        <></>
                        }

                        <p>Оплата:</p>
                        <label 
                        className={multiplier === 1
                            ?
                                !user || user.balance < totalCost || !payMethod.includes('bonus') ? "popup__order-radio-label popup__order-radio-label_disabled" : "popup__order-radio-label"
                            :
                                !user || user.balance < countSumMultiplier() || !payMethod.includes('bonus')? "popup__order-radio-label popup__order-radio-label_disabled" : "popup__order-radio-label"
                            } 
                        title={user 
                            ? multiplier === 1 
                                ? user.balance < totalCost ? `Не достаточно бонусов, необходимо еще ${totalCost-user.balance}` : 'ок'
                                : user.balance < countSumMultiplier() ? `Не хватает ${countSumMultiplier()-user.balance} бонуса` : 'ок'
                            : 'Необходима авторизация'}
                        >

                            <input className="popup__order-radio" type="radio" name="order-radio" value="" 
                            disabled={
                            multiplier === 1
                            ?
                                !user || user.balance < totalCost || !payMethod.includes('bonus')
                            :
                                !user || user.balance < countSumMultiplier() || !payMethod.includes('bonus')
                            }  
                            checked={values?.pay_method === "bonus"} onChange={() => setValues({ ...values, pay_method: "bonus" })}></input>
                            <span className="popup__order-visible-radio"></span>
                            Оплата бонусами
                        </label>
                        <label className={!payMethod.includes('cash') || values.pickup ? "popup__order-radio-label popup__order-radio-label_disabled" : "popup__order-radio-label"}>
                            <input className="popup__order-radio" type="radio" name="order-radio" value="" checked={values?.pay_method === "cash" && !values?.pickup} onChange={() => setValues({ ...values, pay_method: "cash" })} disabled={!payMethod.includes('cash') || values.pickup}></input>
                            <span className="popup__order-visible-radio"></span>
                            Оплата наличными
                        </label>
                        <label className={!payMethod.includes('paykeeper') ? "popup__order-radio-label popup__order-radio-label_disabled" : "popup__order-radio-label"}>
                            <input className="popup__order-radio" type="radio" name="order-radio" value="" checked={values?.pay_method === "paykeeper"} onChange={() => setValues({ ...values, pay_method: "paykeeper" })} disabled={!payMethod.includes('paykeeper')}></input>
                            <span className="popup__order-visible-radio"></span>
                            Оплата картой на сайте
                        </label>
                        <label className={!payMethod.includes('post') ? "popup__order-radio-label popup__order-radio-label_disabled" : "popup__order-radio-label"}>
                            <input className="popup__order-radio" type="radio" name="order-radio" value="" checked={values?.pay_method === "post"} onChange={() => setValues({ ...values, pay_method: "post" })} disabled={!payMethod.includes('post')}></input>
                            <span className="popup__order-visible-radio"></span>
                            Оплата картой курьеру
                        </label>

                        <div className={values?.pay_method !== "cash" ? 'popup__order-change-container' : 'popup__order-change-container popup__order-change-container_opened'}>
                            <label className="popup__order-checkbox-label">
                                <input className="popup__order-checkbox" type="checkbox" name="order-checkbox" value="" checked={values?.need_change} onChange={handleChange('need_change')}></input>
                                <span className="popup__order-visible-checkbox"></span>
                                Сдача с:
                            </label>
                            <input type="text" name="" className="popup__order-inuput popup__order-inuput_small" placeholder="Например 5000" disabled={!values?.need_change} onChange={handleChange('alotofmoney')} value={values?.alotofmoney}></input>
                        </div>

                        {user && values?.pay_method === "paykeeper" &&
                            <>
                                <p className="">Выберите карту для оплаты:</p>
                                <select className="popup__order-inuput popup__order-inuput_small" value={values?.card_id} onChange={handleChange('card_id')}>
                                    {user?.cards.map(c => <option key={c.id} value={c.id}>{c.card_number}</option>)
                                    }
                                    <option value={'new'}>Другая карта</option>
                                </select>
                                {values?.card_id === 'new' &&
                                    <label className="popup__order-checkbox-label popup__order-checkbox-label_block">
                                        <input className="popup__order-checkbox" type="checkbox" name="order-checkbox" value="" checked={values?.save_card} onChange={handleChange('save_card')}></input>
                                        <span className="popup__order-visible-checkbox"></span>
                                        Сохранить новую карту
                                    </label>
                                }
                            </>
                            
                        }
                        
                        {!user && <h3 className="popup__order-info"> <strong>Зарегистрируйтесь в личном кабинете.</strong> 
                        Это позволит вам:
                        <ul>
                            <li><strong>Привязать карту</strong> для оплаты заказов</li>
                            <li> <strong>Сохранить адреса</strong> доставки</li>
                            <li> Получать <strong>постоянный кешбэк</strong> и пользоваться акциями для зарегистрированных пользователей</li>
                        </ul>
                        <br></br> Больше не придется каждый раз вводить свой номер телефона и другую информацию, необходимую для совершения заказа, кроме того, вы сможете <strong>экономить на каждом заказе!</strong></h3>}

                        {error_message.length ? <p className="popup__order-error-message"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error_message}</p> : null}
                        {success.length ? <p className="popup__order-success-message"><i className="fa fa-check" aria-hidden="true"></i> {success}</p> : null}

                    </div>

                    <button type='submit' className="popup__order-submit" disabled={isOrderLoading}>ОФОРМИТЬ ЗАКАЗ</button>
                </form>

                <p className="popup__order-note">
                    Внимание! Поля, помеченые звездочкой, обязательны к заполнению <br></br>
                    <i className="fa fa-lock" aria-hidden="true"></i> Мы гарантируем безопасность Ваших данных.
                </p>

                <i className="fa fa-times popup__close" aria-hidden="true" onClick={()=>{props.setIsOrder(false)}}></i>

            </div>

                <form action={!props.info || props.info === 'default' || !props.info.pk_server ? '' : props.info.pk_server} method="POST" id="pkform" className='pkform'>
                    <input type="hidden" name="sum" />
                    <input type="hidden" name="clientid" />
                    <input type="hidden" name="orderid" />
                    <input type="hidden" name="service_name" />
                    <input type="hidden" name="sign" />
                    <input type="hidden" name="cart" />
                    <input type="hidden" name="msgtype"/>
                    <input type="hidden" name="user_result_callback" value="https://mrdoner.veryfood.ru/"/>
                </form>

        </div>
    );
}
    
export default OrderPopup;